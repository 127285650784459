import React, { useEffect, useState } from 'react';
import axios from 'axios';
import InstagramFeed from 'react-ig-feed';
import 'react-ig-feed/dist/index.css';
import PropTypes from 'prop-types';

const Promotion = ({ item }) => {
	const [RefreshToken, setRefreshToken] = useState(process.env.REACT_APP_INSTA_TOKEN);
	const [IGImage, setIGImage] = useState([]);

	const [show, hide] = useState(true);
	useEffect(() => {
		const fetchFeeds = async () => {
			try {
				const response = await axios.get(
					`https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=${RefreshToken}`,
				);
				if (response.status === 200) {
					setRefreshToken(response.data.access_token);
					hide(true);
				}
			} catch (error) {
				hide(false);
				return error;
			}
		};
		fetchFeeds();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	var url =
		'https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url,caption&&access_token=' +
		RefreshToken;
	React.useEffect(
		function () {
			var fetchData = function fetchData() {
				try {
					fetch(url)
						.then(function (response) {
							return response.json();
						})
						.then(function (result) {
							setIGImage(result.data);
						})
						['catch'](function (error) {
							return;
						});
					return Promise.resolve();
				} catch (e) {
					return Promise.reject(e);
				}
			};
			fetchData();
		},
		[url],
	);

	const handleUrl = (url) => {
		const videoId = url?.split('watch?v=')[1];
		const equal = videoId?.split('&')[0];
		const embeddedUrl = `https://www.youtube.com/embed/${equal}`;
		return embeddedUrl;
	};

	return (
		<>
			<section className='PromotionSection'>
				<div className='container'>
					<div className="row text-center mb-3 d-flex justify-content-center align-items-center">
						<h4 className='textblue'>Follow us on Instagram @nbysfofficial</h4>
					</div>
					<div className='row d-flex flex-column justify-content-center align-items-center'>
						<div className='col-lg-9 desktopIG'>
							{show && <InstagramFeed token={`${RefreshToken}`} counter='8' />}
						</div>
						<div className='mobileIG'>
							{IGImage?.length > 0 &&
								IGImage?.slice(0, 8)?.map((item) => (
									<a
										target='_blank'
										href={item?.permalink}
										style={{ cursor: 'pointer' }}
										rel='noreferrer'
										key={item?.id}>
										<div className='mobileIGList'>
											{item?.media_type === 'IMAGE' ? (
												<img
													src={item?.media_url}
													width={200}
													height={200}
													alt='Instagram'></img>
											) : (
												<iframe
													title='instagram'
													src={item?.media_url}
													width={200}
													height={200}
													alt='Instagram'></iframe>
											)}
										</div>
									</a>
								))}
						</div>

						<div className='row my-4 d-flex justify-content-center align-items-center'>
							<div className='col-lg-12 d-flex justify-content-center align-items-center'>
								<a href='/ticketdetail'>
									<button className='btn-txt1 ticketButtonMain'>
										{' '}
										Click here to GET TICKETS{' '}
									</button>
								</a>
							</div>
						</div>

						<div className='col-lg-12 col-sm-12'>
							<div className='PromotionRight'>
								<div className='yTubeContainer'>
									{item?.youtubeLink && (
										<iframe
											src={handleUrl(item?.youtubeLink)}
											title='YouTube video player'
											frameBorder='0'
											allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
											allowFullScreen></iframe>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Promotion;

Promotion.propTypes = {
	item: PropTypes.object.isRequired,
};
