import React from 'react'
import Animation_Card from './Animation_Card'
import Festival_Highlights from './Festival_Highlights'
import Home_banner from './Home_banner'
import MapSection from './MapSection'
import Promotion from './Promotion'
import Seo_Widget from './Seo_Widget'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EventPageData, HomePageData } from '../Redux/Slice'
import SliderScroll from './SliderScroll'
import Spinner from '../component/Spinner'

const Home = ({items}) => {
  const dispatch = useDispatch()
  
  // useEffect(()=>{
  //   dispatch(EventPageData())
  // },[])




  const applyStyles = {
    fontFamily:items?.fontFamily,
    color: items?.fontColor,
  };



  return items =="" && <Spinner/> || (
    <div style={applyStyles}>
      <Home_banner item={items} />
      <Festival_Highlights item={items} />
      <Seo_Widget item={items}/>
      <Animation_Card item={items}/>
      <SliderScroll item={items}/>
      <Promotion item={items}/>
       {/* <MapSection item={items}/> */}
    </div>
  )
}

export default Home

