import React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import festivelogo from '../assets/festivBlackText.png';
import PropTypes from 'prop-types';

const Header = ({ items }) => {
	const applyStyles = {
		fontFamily: items?.fontFamily,
		color: items?.fontColor,
	};
	return (
		<>
			<header className='Header-bottom'>
				<Navbar collapseOnSelect expand='lg' className='navbg' style={applyStyles}>
					<Container className='Header-bottom-container'>
						<Navbar.Brand>
							<Nav.Link href='/'>
								<img  src={festivelogo} className='Header-logo' alt='Festiv Logo' />
							</Nav.Link>
						</Navbar.Brand>
						<Navbar.Toggle aria-controls='responsive-navbar-nav' />
						<Navbar.Collapse id='responsive-navbar-nav' className='navbar'>
							<Nav className='nav_links ' style={{ marginLeft: 'auto' }}>
								<span className='dropdown-container'>
									<Nav.Link
										className='nav-link Header-Text'
										href='/event'
										style={applyStyles}>
										EVENTS
									</Nav.Link>
								</span>
								<span className='dropdown-container'>
									<Nav.Link className='nav-link Header-Text' style={applyStyles}>
										GET INVOLVED
									</Nav.Link>
									<div className='sub-menu' style={applyStyles}>
										<a href='/sponsor'>Sponsor Opportunities</a>
										<a href='/vendor'>Vendor Opportunities</a>
									</div>
								</span>
								<span className='dropdown-container'>
									<Nav.Link
										className='nav-link Header-Text'
										href='/festivalhours'
										style={applyStyles}>
										FESTIVAL HOURS
									</Nav.Link>
								</span>
								<span className='dropdown-container'>
									<Nav.Link className='nav-link Header-Text' style={applyStyles}>
										ABOUT
									</Nav.Link>
									<div className='sub-menu' style={applyStyles}>
										<a href='/about'>About Us</a>
										<a href='/contact'>Contact Us</a>
									</div>
								</span>
							</Nav>
							<Nav className='button-res-nav'>
								<Nav.Link href='/ticketdetail' className='btn-txt1 ticket'>
									GET TICKETS
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</header>
		</>
	);
};

export default Header;
Header.propTypes = {
	items: PropTypes.object.isRequired,
};
