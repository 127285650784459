import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
	HomePageList: '',
	EventPageList: [],
	EventDetailsData: null,
	EventId: '',
	EventNameList: [],
	SponsorAdminData: '',
	VendorAdminData: '',
	AboutAdminData: '',
	FestivalHoursAdminData: '',
	TicketReedemDate: '',
	Loading: false,
	error: '',
	success: '',
	status: '',
};

export const HomePageData = createAsyncThunk(
	'page/HomePageData',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_AWS_URL}/homepage/listHomePage`,
			);
			if (response.status === 200 || response.status === 201) {
				const { data } = response;
				return data[0];
			}
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message);
		}
	},
);

export const EventPageData = createAsyncThunk(
	'page/EventPageData',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_AWS_URL}/eventpage/listEvenPage`,
			);
			if (response.status === 200 || response.status === 201) {
				const { data } = response;
				return data[0];
			}
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message);
		}
	},
);

export const EventPageDetails = createAsyncThunk(
	'page/EventPageDetails',
	async (val, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_AWS_URL}/eventpage/listEvenPageByEventId/${val}`,
			);
			if (response.status === 200 || response.status === 201) {
				const { data } = response;
				return data[0];
			}
		} catch (error) {
			return rejectWithValue('');
		}
	},
);

export const GetTicketFeesData = createAsyncThunk(
	'ticket/GetTicketFeesData',
	async (val, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_AWS_URL}/feesStructure/listTicketFeesStructure/,?ticketName=${val}`,
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						website: 'yes',
					},
				},
			);

			if (response.status === 200 || response.status === 201) {
				const { data } = response;

				return data;
			}
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message);
		}
	},
);

export const ListEventNameList = createAsyncThunk(
	'page/ListEventName',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_AWS_URL}/event/listEventName`,
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				},
			);
			if (response.status === 200 || response.status === 201) {
				const { data } = response;
				return data;
			}
		} catch (error) {
			return rejectWithValue('');
		}
	},
);

export const Sponsor = createAsyncThunk('page/Sponsor', async (val, { rejectWithValue }) => {
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_AWS_URL}/sponsor/createSponsor`,
			val,
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			},
		);
		if (response.status === 200 || response.status === 201) {
			const { data } = response;
			return data?.message;
		}
	} catch (error) {
		return rejectWithValue(error?.response?.data?.message || error?.response?.data?.error);
	}
});

export const SponsorData = createAsyncThunk(
	'page/SponsorData',
	async (val, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_AWS_URL}/listAdminSponsorPage`,
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				},
			);
			if (response.status === 200 || response.status === 201) {
				const { data } = response;
				return data[0];
			}
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message);
		}
	},
);

export const VendorPost = createAsyncThunk('page/VendorPost', async (val, { rejectWithValue }) => {
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_AWS_URL}/vendor/createVendor`,
			val,
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			},
		);
		if (response.status === 200 || response.status === 201) {
			const { data } = response;
			return data?.message;
		}
	} catch (error) {
		return rejectWithValue(error?.response?.data?.message || error?.response?.data?.error);
	}
});

export const VendorData = createAsyncThunk('page/VendorData', async (_, { rejectWithValue }) => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_AWS_URL}/listAdminVendorPagee`, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		});
		if (response.status === 200 || response.status === 201) {
			const { data } = response;
			return data[0];
		}
	} catch (error) {
		return rejectWithValue(error?.response?.data?.message);
	}
});

export const AboutData = createAsyncThunk('page/AboutData', async (_, { rejectWithValue }) => {
	try {
		const response = await axios.get(
			`${process.env.REACT_APP_AWS_URL}/aboutpage/listAboutPage`,
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			},
		);
		if (response.status === 200 || response.status === 201) {
			const { data } = response;
			return data[0];
		}
	} catch (error) {
		return rejectWithValue(error?.response?.data?.message);
	}
});

export const FestivalHoursData = createAsyncThunk(
	'page/FestivalHoursData',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_AWS_URL}/FestivalHoursPage/listFestivalHoursPage`,
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				},
			);
			if (response.status === 200 || response.status === 201) {
				const { data } = response;
				return data[0];
			}
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message);
		}
	},
);

export const Subscription = createAsyncThunk(
	'page/Subscription',
	async (val, { rejectWithValue }) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_AWS_URL}/subscribe/createSubscribe`,
				val,
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				},
			);
			if (response.status === 200 || response.status === 201) {
				const { data } = response;
				return data?.message;
			}
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error?.response?.data?.error);
		}
	},
);

export const LocateTicketing = createAsyncThunk(
	'page/LocateTicket',
	async (val, { rejectWithValue }) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_AWS_URL}/locateTicket`,
				val,
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				},
			);
			if (response.status === 200 || response.status === 201) {
				const { data } = response;
				return data?.message;
			}
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message);
		}
	},
);

export const PaymentGateway = createAsyncThunk(
	'payment/paymentGateway',
	async (val, { rejectWithValue }) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_AWS_URL}/paymentGateway`,
				val,
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				},
			);
			if (response.status === 200 || response.status === 201) {
				sessionStorage.removeItem('cart');
				const { data } = response;
				return data?.message;
			}
		} catch (error) {
			return rejectWithValue(error?.response?.data?.Message || error?.response?.data?.error);
		}
	},
);

export const RedeemData = createAsyncThunk(
	'ticket/RedeemData',
	async (val, { rejectWithValue }) => {
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_AWS_URL}/ticketData?orderId=${val}`,
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				},
			);
			if (response.status === 200 || response.status === 201) {
				const { data } = response;
				return data;
			}
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message);
		}
	},
);

export const RedeemTicketScan = createAsyncThunk(
	'scan/RedeemTicketScan',
	async (val, { rejectWithValue }) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_AWS_URL}/ticketVerification`,
				val,
				{
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
					},
				},
			);
			if (response.status === 200 || response.status === 201) {
				const { data } = response;
				return [data?.status, data?.message];
			}
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error?.response?.data?.error);
		}
	},
);

const ReduxSlice = createSlice({
	name: 'festiv',
	initialState: initialState,
	reducers: {
		errorMessage: (state, action) => {
			state.error = action.payload.errors;
		},
		successMessage: (state, action) => {
			state.success = action.payload.successess;
		},
		loadingStatus: (state, action) => {
			state.Loading = action.payload.loadingStatus;
		},
		RedeemStatus: (state, action) => {
			state.status = action.payload.RedeemStatus;
		},
	},
	extraReducers: (builder) => {
		builder
			// HomePage reducer
			.addCase(HomePageData.pending, (state) => {
				state.Loading = true;
			})
			.addCase(HomePageData.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.HomePageList = action.payload;
			})
			.addCase(HomePageData.rejected, (state, action) => {
				state.error = action.payload;
				state.Loading = false;
			})

			//EventPage Data List

			.addCase(EventPageData.pending, (state) => {
				state.Loading = true;
				state.EventPageList = [];
			})
			.addCase(EventPageData.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.EventPageList = action.payload;
			})
			.addCase(EventPageData.rejected, (state, action) => {
				state.Loading = false;
				state.error = action.payload;
			})

			//Event Details Page

			.addCase(EventPageDetails.pending, (state) => {
				state.Loading = false;
			})
			.addCase(EventPageDetails.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.EventDetailsData = action.payload;
			})
			.addCase(EventPageDetails.rejected, (state, action) => {
				state.Loading = false;
				state.EventDetailsData = null;
				state.error = action.payload;
			})

			.addCase(ListEventNameList.pending, (state) => {
				state.Loading = false;
			})
			.addCase(ListEventNameList.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.EventNameList = action.payload;
			})
			.addCase(ListEventNameList.rejected, (state, action) => {
				state.Loading = false;
				state.EventNameList = null;
				state.error = action.payload;
			})

			//sponsor

			.addCase(Sponsor.pending, (state) => {
				state.Loading = false;
			})
			.addCase(Sponsor.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.success = action.payload;
			})
			.addCase(Sponsor.rejected, (state, action) => {
				state.Loading = false;
				state.success = null;
				state.error = action.payload;
			})

			.addCase(SponsorData.pending, (state) => {
				state.Loading = false;
			})
			.addCase(SponsorData.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.SponsorAdminData = action.payload;
			})
			.addCase(SponsorData.rejected, (state, action) => {
				state.Loading = false;
				state.SponsorAdminData = null;
				state.error = action.payload;
			})

			.addCase(VendorPost.pending, (state) => {
				state.Loading = false;
			})
			.addCase(VendorPost.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.success = action.payload;
			})
			.addCase(VendorPost.rejected, (state, action) => {
				state.Loading = false;
				state.success = null;
				state.error = action.payload;
			})

			.addCase(VendorData.pending, (state) => {
				state.Loading = false;
			})
			.addCase(VendorData.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.VendorAdminData = action.payload;
			})
			.addCase(VendorData.rejected, (state, action) => {
				state.Loading = false;
				state.VendorAdminData = null;
				state.error = action.payload;
			})

			.addCase(AboutData.pending, (state) => {
				state.Loading = false;
			})
			.addCase(AboutData.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.AboutAdminData = action.payload;
			})
			.addCase(AboutData.rejected, (state, action) => {
				state.Loading = false;
				state.AboutAdminData = null;
				state.error = action.payload;
			})

			.addCase(FestivalHoursData.pending, (state) => {
				state.Loading = false;
			})
			.addCase(FestivalHoursData.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.FestivalHoursAdminData = action.payload;
			})
			.addCase(FestivalHoursData.rejected, (state, action) => {
				state.Loading = false;
				state.FestivalHoursAdminData = null;
				state.error = action.payload;
			})

			.addCase(Subscription.pending, (state) => {
				state.Loading = false;
			})
			.addCase(Subscription.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.success = action.payload;
			})
			.addCase(Subscription.rejected, (state, action) => {
				state.Loading = false;
				state.success = null;
				state.error = action.payload;
			})

			.addCase(LocateTicketing.pending, (state) => {
				state.Loading = false;
			})
			.addCase(LocateTicketing.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.success = action.payload;
			})
			.addCase(LocateTicketing.rejected, (state, action) => {
				state.Loading = false;
				state.success = null;
				state.error = action.payload;
			})

			.addCase(PaymentGateway.pending, (state) => {
				state.Loading = false;
			})
			.addCase(PaymentGateway.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.success = action.payload;
			})
			.addCase(PaymentGateway.rejected, (state, action) => {
				state.Loading = false;
				state.success = null;
				state.error = action.payload;
			})

			.addCase(RedeemData.pending, (state) => {
				state.Loading = false;
			})
			.addCase(RedeemData.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.TicketReedemDate = action.payload;
			})
			.addCase(RedeemData.rejected, (state, action) => {
				state.Loading = false;
				state.success = null;
				state.error = action.payload;
			})

			.addCase(RedeemTicketScan.pending, (state) => {
				state.Loading = true;
			})
			.addCase(RedeemTicketScan.fulfilled, (state, action) => {
				state.Loading = false;
				state.error = '';
				state.status = action.payload[0];
				state.success = action.payload[1];
			})
			.addCase(RedeemTicketScan.rejected, (state, action) => {
				state.Loading = false;
				state.status = '';
				state.success = '';
			});
	},
});

export const { errorMessage, successMessage, loadingStatus, RedeemStatus } = ReduxSlice.actions;
export default ReduxSlice.reducer;
